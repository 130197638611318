<template>
  <li>
    <router-link
      class="s-flex s-items-center s-py-5"
      :to="{ name: 'LiffMemberCardTransactionsShow', params: { id } }"
    >
      <div class="s-mr-auto">
        <p class="s-font-semibold s-text-gray-darker s-text-lg">{{ transaction.branch.name }}</p>
        <time class="s-text-gray-dark s-text-xs">{{ time }}</time>
      </div>
      <div class="s-text-secondary s-font-semibold s-mr-3">
        <span class="s-text-sm">NT$&nbsp;</span>
        <span class="s-text-xl">{{ transaction.total }}</span>
      </div>
      <div class="s-text-gray s-font-lg">&gt;</div>
    </router-link>
  </li>
</template>

<script>
  import moment from 'moment'
  import { encode } from '@/utils/Base64'

  export default {
    props: {
      transaction: {
        type: Object,
        required: true,
      },
    },
    computed: {
      id () {
        const branch = this.transaction.branch.branch_code
        const order = this.transaction.outer_order_number
        const time = this.transaction.order_at
        const machine = this.transaction.machine_name

        return encode({ branch, order, time, machine })
      },
      time () {
        return moment(this.transaction.order_at).format('YYYY-MM-DD HH:mm')
      },
    },
  }
</script>
