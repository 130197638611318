<template>
  <div
    class="s-modal"
    :class="{ 'g-modal-no-actions': noActions }"
    v-if="shown"
  >
    <div class="s-modal-backdrop" />
    <div class="s-modal-panel">
      <div
        class="s-modal-header"
        :class="{ 's-modal-header--has-bottom': hasHeader }"
      >
        <span class="modal-header-title" v-if="hasHeader">{{ title }}</span>
        <i class="s-modal-close-icon" @click="close"></i>
      </div>
      <div class="s-modal-body">
        <slot />
      </div>
      <div class="s-modal-actions" v-if="!noActions">
        <slot name="actions" :close="close">
          <button class="s-btn s-btn-primary" type="button" @click="close">
            {{ buttonText }}
          </button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    noActions: {
      type: Boolean,
    },
    buttonText: {
      type: String,
      default: "確定",
    },
    hasHeader: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    shown: false,
    closeCallback: null,
  }),
  methods: {
    open(callback = null) {
      this.closeCallback = callback;
      this.shown = true;
    },
    close() {
      this.shown = false;

      if (typeof this.closeCallback === "function") {
        this.closeCallback();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.s-modal,
.s-modal-backdrop {
  text-align: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.s-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5rem 1rem;
  z-index: 50;
}

.s-modal-backdrop {
  background-color: var(--s-black);
  opacity: 0.3;
}

.s-modal-panel {
  position: relative;
  background-color: var(--s-white);
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 1.25rem 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  max-height: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
}

.s-modal-header {
  padding: 1rem 0.75rem;
  color: var(--s-gray-darker);
  font-weight: var(--s-weight-semibold);
  align-items: center;

  &--has-bottom {
    border-bottom: solid 1px var(--s-gray-lighter);
  }
}

.s-modal-close-icon {
  position: absolute;
  right: 1.25em;
  top: 1.25em;
  width: 1em;
  height: 1em;
  cursor: pointer;
  margin-left: auto;
}

.s-modal-close-icon:before,
.s-modal-close-icon:after {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--s-black);
}

.s-modal-close-icon:before {
  transform: rotate(45deg);
}

.s-modal-close-icon:after {
  transform: rotate(-45deg);
}

.s-modal-body {
  padding: 1.25rem 0.75rem 0 0.75rem;
  min-height: 6rem;
  margin-bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.s-modal-actions {
  padding: 0 0.75rem 1.25rem 0.75rem;
  margin-top: auto;
}

.s-modal-no-actions .s-modal-body {
  padding-bottom: 1.25rem;
  margin-bottom: 0;
}

.modal-header-title {
  margin: 0px auto;
}
</style>
