<template>
  <div class="s-orders-show">
    <p v-if="fetching"><Loading /></p>
    <template v-else>
      <Hero title="消費明細" />
      <div class="s-space-y-2">
        <DetailSection icon="info" title="消費資訊">
          <table class="s-text-sm s-w-full">
            <tr>
              <td class="s-font-semibold">門市</td>
              <td class="s-text-right">{{ transaction.branch.name }}</td>
            </tr>
            <tr>
              <td class="s-font-semibold">時間</td>
              <td class="s-text-right">{{ time }}</td>
            </tr>
            <tr>
              <td class="s-font-semibold">消費金額</td>
              <td class="s-text-right s-font-semibold">NT$ {{ transaction.total }}</td>
            </tr>
            <tr>
              <td class="s-font-semibold">新增紅利點數</td>
              <td class="s-text-right">{{ transaction.point }}</td>
            </tr>
            <tr>
              <td class="s-font-semibold">發票號碼</td>
              <td class="s-text-right">{{ transaction.invoice.invoice_number }}</td>
            </tr>
            <tr>
              <td class="s-font-semibold">發票類型</td>
              <td class="s-text-right">{{ transaction.invoice.invoice_type }}</td>
            </tr>
          </table>
        </DetailSection>
        <DetailSection icon="list" title="消費明細">
          <table class="s-text-sm s-w-full">
            <thead>
              <tr class="s-font-semibold">
                <th>名稱</th>
                <th class="s-text-right">數量</th>
                <th class="s-text-right">金額</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="i" v-for="(item, i) in transaction.items">
                <td>{{ item.title }}</td>
                <td class="s-text-right">{{ item.quantity }}</td>
                <td class="s-text-right">{{ item.total_payment }}</td>
              </tr>
            </tbody>
          </table>
        </DetailSection>
        <DetailSection icon="currency" title="付款資訊">
          <table class="s-text-sm s-w-full">
            <thead>
              <tr class="s-font-semibold">
                <th>付款方式</th>
                <th class="s-text-right">付款金額</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="i" v-for="(payment, i) in payments">
                <td>{{ payment.type }}</td>
                <td class="s-text-right">NT$ {{ payment.amount }}</td>
              </tr>
            </tbody>
          </table>
        </DetailSection>
      </div>
    </template>
  </div>
</template>

<script>
  import moment from 'moment'
  import { mapActions } from 'vuex'
  import Loading from '@/components/Page/Liff/Shared/Loading'
  import Hero from '@/components/Page/Liff/MemberCard/Hero'
  import DetailSection from '@/components/Page/Liff/MemberCard/Transactions/DetailSection'
  import withFetcher from '@/mixins/liff/withFetcher'
  import time from '@/mixins/liff/memberCard/transaction/time'
  import { decode } from '@/utils/Base64'

  export default {
    mixins: [withFetcher, time],
    components: { Loading, Hero, DetailSection },
    data: () => ({
      transaction: {},
    }),
    computed: {
      payload () {
        const { branch, order, time, machine } = decode(this.$route.params.id)

        return { branch, order, time, machine }
      },
      payments () {
        return this.transaction.invoice.payment_type.trim().split(' ').map((row) => {
          const [type, amount] = row.split(':')

          return { type, amount }
        })
      },
      time () {
        return moment(this.payload.time).format('YYYY-MM-DD HH:mm')
      },
    },
    methods: {
      ...mapActions({
        getTransaction: 'liffMemberCard/getTransaction',
      }),
      async fetch () {
        this.transaction = await this.getTransaction(this.payload)
      },
    },
  }
</script>

<style scoped>
  .s-orders-show {
    margin: -1rem -0.75rem 0;
  }

  table th,
  table tr:not(:last-child) td {
    padding-bottom: 1.25rem;
  }
</style>
