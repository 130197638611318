<template>
  <div>
    <p v-if="fetching"><Loading /></p>
    <template v-else>
      <Notification class="s-mb-3" ref="notification" @close="handleNotificationClosed" />
      <div class="s-mb-4">
        <img class="s-block s-w-full s-rounded-lg s-drop-shadow" :src="membarCardImage">
      </div>
      <div class="s-rounded-lg s-bg-white s-card">
        <div class="s-font-semibold s-flex">
          <MemberIcon class="s-text-secondary s-mr-2" />
          <span class="s-text-xl s-mr-3">{{ card.name }}</span>
          <span>{{ card.mobile_phone }}</span>
        </div>
        <div class="text-center">
          <img class="s-block s-w-full s-mb-2" :src="card.barcode_url">
          <span class="text-xs">Card ID {{ card.card_number }}</span>
        </div>
        <div>
          <dl class="s-text-secondary">
            <dt class="s-text-sm">紅利點數</dt>
            <dd>
              <span class="s-font-bold s-text-xl">{{ card.point }}</span>
              <span class="s-font-semibold s-text-xs">點</span>
            </dd>
          </dl>
          <dl class="s-text-gray-dark">
            <dt class="s-text-sm">{{ thisYear }}/12/31 到期點數</dt>
            <dd>
              <span class="s-font-bold s-text-xl">{{ card.this_expired_point }}</span>
              <span class="s-font-semibold s-text-xs">點</span>
            </dd>
          </dl>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import Loading from '@/components/Page/Liff/Shared/Loading'
  import MemberIcon from '@/components/Page/Liff/MemberCard/Icon/Member'
  import withFetcher from '@/mixins/liff/withFetcher'
  import notification from '@/mixins/liff/memberCard/notification'

  export default {
    mixins: [withFetcher, notification],
    components: { Loading, MemberIcon },
    data: () => ({
      card: {},
    }),
    computed: {
      membarCardImage () {
        if (this.card.is_vip) return require('@/assets/images/liff/member-card/gold.png')
        return require('@/assets/images/liff/member-card/standard.png')
      },
      thisYear () {
        return new Date().getFullYear()
      },
    },
    methods: {
      ...mapActions({
        getCard: 'liffMemberCard/getCard',
      }),
      async fetch () {
        this.card = await this.getCard()
      },
    }
  }
</script>

<style scoped>
  .s-card {
    padding: 0.25rem 0;
  }

  .s-card > * {
    padding: 1rem 0.75rem;
  }

  .s-card > *:not(:last-child) {
    border-bottom: 1px solid var(--s-gray-light)
  }

  dl {
    display: flex;
    justify-content: space-between;
  }

  dl:first-child {
    margin-bottom: 1.5rem;
  }

  dl dd span:first-child {
    margin-right: .25rem;
  }
</style>
