export function encode(plain) {
  if (!plain || !Object.keys(plain).length) {
    return null
  }

  return universalEncode(JSON.stringify(plain)).replace(/\+/g, '-').replace(/\//g, '_').replace(/=*$/, '')
}

export function decode(encoded) {
  if (!encoded) {
    return null
  }

  return JSON.parse(universalDecode(encoded.replace(/-/g, '+').replace(/_/g, '/')))
}

function universalEncode(plain) {
  if (process.client) {
    return btoa(plain)
  }

  return Buffer.from(plain).toString('base64')
}

function universalDecode(encoded) {
  if (process.client) {
    return atob(encoded)
  }

  return Buffer.from(encoded, 'base64').toString()
}
