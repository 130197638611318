<template>
  <div class="s-layout">
    <div class="s-container">
      <p class="s-text-xl" v-if="!initialized">Initializing...</p>
      <router-view v-else-if="!errorOccurred" />
    </div>
    <Alert ref="alert" />
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import currency from '@/mixins/liff/currency'
  import error from '@/mixins/liff/error'
  import Alert from '@/components/Page/Liff/Shared/Alert'

  export default {
    mixins: [currency, error],
    components: { Alert },
    computed: {
      ...mapState({
        initialized: (state) => state.liffMemberCard.initialized,
      }),
      ...mapGetters({
        user: 'liffMemberCard/user',
      }),
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/css/liff/main.css";

  .s-layout,
  .s-layout *,
  .s-layout *:before,
  .s-layout *:after {
    --s-primary: #e95383, #f6c65a;
    --s-secondary: #de006f;
    --s-secondary-light: #fef7f9;
    --s-warning: #ed6c00;
    --s-danger: #de0071;
    --s-gray-darker: #2c2c2e;
    --s-gray-dark: #636366;
    --s-gray: #b7b7b7;
    --s-gray-light: #e5e5ea;
    --s-gray-lighter: #f2f2f7;
  }

  .s-layout {
    color: var(--s-gray-dark);
    background-color: var(--s-gray-lighter);
  }

  .s-container {
    padding: 1rem 0.75rem;
    min-height: 100vh;
  }
</style>
