import { mapActions } from 'vuex'

const PAGE_TITLE = {
  LiffMemberCardAuthMe: '行動會員卡',
  LiffMemberCardTransactionsIndex: '消費紀錄',
  LiffMemberCardTransactionsShow: '消費明細',
  LiffMemberCardPointsIndex: '紅利點數',
}

const PAGE_BEHAVIOR = {
  LiffMemberCardTransactionsShow ($router) {
    return $router.replace({ name: 'LiffMemberCardTransactionsIndex' })
  },
}

export default {
  provide () {
    return {
      $error: this.$error,
    }
  },
  data: () => ({
    errorOccurred: false,
  }),
  computed: {
    debugMessage () {
      return [
        `錯誤代碼：${this.user.customer().id}`,
        `Line UID：${this.user.customer().outer_code}`,
        `會員卡號：${this.user.member().outer_code}`,
        `手機號碼：${this.user.member().mobile_phone}`,
      ].join('\n')
    },
  },
  methods: {
    ...mapActions({
      sso: 'liffMemberCard/sso',
    }),
    async $error (e) {
      this.errorOccurred = true

      const needsRebind = e.status === 400 && e.message === '需重新綁定'
      const title = PAGE_TITLE[this.$route.name]
      const behavior = PAGE_BEHAVIOR[this.$route.name]
      const message = this.withDebugMessage('資料處理發生錯誤，請洽系統管理員。')
      const buttonText = needsRebind ? '重新驗證帳號' : '確定'

      await this.showError(message, title, buttonText)

      if (needsRebind) {
        return this.sso()
      }

      if (behavior) {
        await behavior(this.$router)
      }

      window.location.reload()
    },
    showError (message, title = '', buttonText = '確定') {
      return new Promise((resolve) => {
        this.$nextTick(() => {
          this.$refs.alert.show(message, title, buttonText).then(resolve)
        })
      })
    },
    withDebugMessage (message) {
      return `${message}\n${this.debugMessage}`
    }
  },
  created () {
    this.errorOccurred = !!this.$route.query.error
  },
  async mounted () {
    if (!this.errorOccurred) {
      return
    }

    await this.showError(
      this.withDebugMessage(this.$route.query.error),
      'SSO'
    )

    window.location = this.$route.fullPath.replace(/(\?|&)error=[^?&]+/, '')
  },
}
