<template>
  <Modal :title="title" :button-text="buttonText" ref="modal">
    <div v-html="normalizedMessage" />
  </Modal>
</template>

<script>
  import Modal from '@/components/Page/Liff/Shared/Modal'

  export default {
    components: { Modal },
    data: () => ({
      title: '',
      message: '',
      buttonText: '',
    }),
    methods: {
      show (message, title = '', buttonText = '確定') {
        return new Promise((resolve) => {
          this.title = title
          this.message = message
          this.buttonText = buttonText
          this.$refs.modal.open(resolve)
        })
      },
    },
    computed: {
      normalizedMessage () {
        return this.message.replace(/<\/?[a-z0-9]+.*?>/ig, '').replace(/\r\n|\n\r|\n|\r/g, '<br>')
      },
    },
  }
</script>
