export default {
  provide () {
    return {
      $currency (number, prefix = '$') {
        if (!number) {
          return 0
        }

        return `${prefix}${number.toString().replace(/(.)(?=(?:\d{3})+$)/g, '$1,').replace(/\.\d+/, '')}`
      }
    }
  },
}
