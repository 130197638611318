<template>
  <div class="s-orders-index">
    <p v-if="fetching"><Loading /></p>
    <template v-else>
      <Hero title="消費紀錄" />
      <div class="s-bg-white s-mb-6">
        <header class="s-p-3 s-border-b s-border-gray-light">
          <PeriodSelect :value="months" @change="setMonths" />
        </header>
        <main class="s-px-3">
          <p class="s-text-xs s-text-center s-pt-3">
            交易紀錄將於 60 分鐘後更新，實際資料以本公司系統紀錄為準
          </p>
          <ul class="s-divide-y s-divide-y-gray-light" v-if="transactions.length">
            <Item :transaction="transaction" :key="i" v-for="(transaction, i) in transactions"/>
          </ul>
          <div class="s-py-40 s-text-center" v-else>
            <img class="s-mb-3" src="@/assets/images/liff/empty.svg">
            <p class="s-text-gray">沒有消費紀錄</p>
          </div>
        </main>
      </div>
      <Pagination :current="page" :last="lastPage" />
    </template>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import Loading from '@/components/Page/Liff/Shared/Loading'
  import Item from '@/components/Page/Liff/MemberCard/Transactions/Item'
  import list from '@/mixins/liff/memberCard/list'

  export default {
    mixins: [list],
    components: { Loading, Item },
    data: () => ({
      transactions: [],
    }),
    methods: {
      ...mapActions({
        getTransactions: 'liffMemberCard/getTransactions',
      }),
      async fetch () {
        const { data: transactions, lastPage } = await this.getTransactions({
          page: this.page,
          months: this.months,
          count: this.count,
        })

        this.transactions = transactions
        this.lastPage = lastPage
      },
    }
  }
</script>

<style scoped>
  .s-orders-index {
    margin: -1rem -0.75rem 0;
  }
</style>
